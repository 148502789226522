import {Box, CssBaseline} from '@mui/material'
import topImage from "./takibi01.jpg"
import {DrawerAppBar} from "./Header"


export const Top = () => {

    return(
        <Box>
            <CssBaseline/> 
            <DrawerAppBar/>
            <div 
                style={{
                  width: "100%",
                  display:"flex",
                  flexFlow: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img src={topImage} 
                style={{
                    width: "90%",
                    margin: "10px 10%"}}
                alt = "toppage"/>
            </div>
        </Box>
    )
}