import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { blog01, blog02 } from './blog';
import { Top } from "./Top"
import { Preprocess } from "./Preprocess"
import { ImageUpload } from "./Image_upload"
import './App.css';

// jsonファイルを作ってルーティング用アドレスとタイトル、記事の中身を一括管理したい。
// map関数を使って代入してコードをシンプルにしたい

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Top/>} />
        {/* <Route path='/blog01' element={<blog01/>} /> */}
        <Route path='/Preprocess' element={<Preprocess/>} />        
        {/* <Route path='/Image' element={<ImageUpload/>} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
