import {
            useState
} from "react"

import { Button,
         Box,
         Input,
        } from '@mui/material';

import {DrawerAppBar} from "./Header"


export const Preprocess = () => {


      
    
    const [inputText, setInputText] = useState("");
    const [textToBeTranslated, setTextToBeTranslated] = useState('');
    const [outputText, TranslateText] = useState('');
    const [delBreak, setDelBreak] = useState(true)
    const [delSpace, setDelSpace] = useState(false)
    const [delSpecificBrackets, setDelSpecificBranckets] = useState(false)
    const [delAllBrackets, setDelAllBranckets] = useState(false)
    const [splitByPeriod, setSplitByPeriod] = useState(false)
    const onClickGetInputText = () => {
        setTextToBeTranslated(inputText);
        TranslateText(DelLineBreak(inputText))
      }
    const onClickDelInputText = () => {
        setInputText("");
        TranslateText("")
    }
    const handleChange = (event) => {
    setInputText(event.target.value);
    };
    
    const checkboxChange = (value, setValue) => {
        setValue(!value);
    };

    String.prototype.toUnicode = function(){
        let result = [];
        for(let i = 0; i < this.length; i++){
            result.push("\\u" + ("000" + this[i].charCodeAt(0).toString(16)).substr(-4));
        }
        return result;
    };

    const DelLineBreak = (inputText) => {
        let outputText = inputText  

        
        // スラッシュを置換する    
        outputText = outputText.replace(/\//g,"_") 
            
        // %を置換する    
        outputText = outputText.replace(/%/g,"Percent")

        // 改行を置換する
        if (delBreak){
            outputText = outputText.replace(/\r?\n+/g," ");
        }

        // 特定の()を削除する
        if (delSpecificBrackets){
            let all_arr = outputText.match(/\((.+?)\)/g);
            let fig_arr = outputText.match(/\(Fig(.+?)\)/g);
            let table_arr = outputText.match(/\(Table(.+?)\)/g);
            let not_in_arr = null
            if ((fig_arr == null) && (table_arr == null)){
                not_in_arr = []
            }else if(fig_arr == null){
                not_in_arr = table_arr
            }else if(table_arr == null){
                not_in_arr = fig_arr
            }else{
                not_in_arr = fig_arr.concat(table_arr)
            }
            let n = 0
            if (all_arr != null){
                n = all_arr.length
            }
            if (n != 0){
               for (let i = 0; i < n; i++){
                   if (not_in_arr.indexOf(all_arr[i]) == -1){
                    outputText = outputText.replace(all_arr[i],"");
                   }
                }
            }
    
        }
        // 全ての括弧を削除する
        if (delAllBrackets){
            let arr = outputText.match(/\((.+?)\)/g);
            let n = 0
            if (arr != null){
                n = arr.length
            }
            if (n != 0){
               for (let i = 0; i < n; i++){
                   outputText = outputText.replace(arr[i],"");
                }
             }
        }
        // ピリオドごとに行間を挿入する
        if (splitByPeriod){
            outputText = outputText.replace(/\./g, ".\n\n");
        }
         
        // スペースを削除する
        if (delSpace){
            outputText = outputText.replace(/ +/g," ");
        }
        let new_url = "https://www.deepl.com/translator#en/ja/";
        new_url += outputText;
        window.open(new_url, '_blank');

        
        return outputText
    }


    return(
        <Box sx={{ fontSize : "20px"}}>
            <DrawerAppBar></DrawerAppBar>
            <Input
                multiline = "True"
                rows = "10"
                placeholder='Before'
                sx={{ width: 1/2 }}
                value={inputText}
                onChange={handleChange}
            />
            <Input
                id='outputText'
                multiline = "True"
                rows = "10"
                placeholder='After'
                sx={{ width: 1/2 }}                
                value={outputText}
            />
            <Button
                onClick={onClickGetInputText}>
                翻訳
            </Button>      
            <Button
                onClick={onClickDelInputText}>
                クリア
            </Button>
            <Box>
                <div>
                    <input
                        type="checkbox"
                        id="check"
                        checked={delBreak}
                        onChange={() => checkboxChange(delBreak,setDelBreak)}
                    />
                    <label htmlFor="check">
                    改行を削除する
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="check"
                        checked={delSpace}
                        onChange={() => checkboxChange(delSpace,setDelSpace)}
                    />
                    <label htmlFor="check">
                    連続したスペースを1つのスペースに置換する
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="check"
                        checked={delSpecificBrackets}
                        onChange={() => checkboxChange(delSpecificBrackets,setDelSpecificBranckets)}
                    />
                    <label htmlFor="check">
                    (Fig. x)と(Table x)以外の()を削除する
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="check"
                        checked={delAllBrackets}
                        onChange={() => checkboxChange(delAllBrackets,setDelAllBranckets)}
                    />
                    <label htmlFor="check">
                    全ての()を削除する
                    </label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="check"
                        checked={splitByPeriod}
                        onChange={() => checkboxChange(splitByPeriod,setSplitByPeriod)}
                    />
                    <label htmlFor="check">
                    ピリオドごとに空行を挿入する
                    </label>
                </div>
            </Box>
        </Box>
    )
}