import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

import logo from "./logo_transparent.png"

import ListIcon from '@mui/icons-material/List';
import ArticleIcon from '@mui/icons-material/Article';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

let navItems = [[<ListIcon/>,'Top',""], [<ArticleIcon/>,'Blog',"#"], [ <HomeRepairServiceIcon/>,'Tools',"Preprocess"]];

navItems = navItems.map(item => {
  const IconComponent = item[0];
  const StyledIcon = React.cloneElement(IconComponent,{
     sx: {display: { xs: 'inherent', sm: 'none' }},
     key: item[1],
  });
  return [StyledIcon, ...item.slice(1)]; // 先頭の要素を置き換える
});


export const DrawerAppBar = (props) => {

  const headerTheme = createTheme({
    palette: {
      mode: 'light',
       primary: {
          main: '#ffffff'
        }
      }  
  })

  const { window } = props;

  const navContents = (
      navItems.map((item) => (
        <Link to={`/${item[2]}`}>
        <Button key={item[1]} sx={{ color: '#000000'}} style={{display:"flex",flexDirection: "column",alignItems: "center",textAlign: "center",fontSize:"15px"}}>
          {item[0]}{item[1]}
        </Button>
        </Link>
      ))
  )

  const container = window !== undefined ? () => window().document.body : undefined;


  // ページタイトルを定義
  const HeaderTitle = () => {
    useEffect(() => {
      document.title = 'CLUMP LUMP';
    }, []);
  }

  return (
    <>
    <Box>   
    {/* <head>{"Access-Control-Allow-Origin:*"}</head> */}
      <CssBaseline />      
      <ThemeProvider theme={headerTheme}>
        <AppBar 
          component="nav"
          color = "primary"
          elevation={0}
          position='relative'
        >
          <Toolbar>
            <Box
              sx={{ display: 'flex',
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center" }}
            >            
              <Link to="/" style={{textAlign:"center"}}>
                <img 
                src={logo}
                style={{
                  width: "50%",
                  margin: "30px auto"}}/>
                </Link>
              <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                {navContents}
              </Box>
              <Box sx={{ 
              position:'fixed',
              display: { xs: "flex", sm: 'none' },  
              bottom: 0, 
              backgroundColor: "white",
              width: "100%",
              flexWrap: "nowrap",
              justifyContent: "space-around"
               }}>
                {navContents}
              </Box>
            </Box>
          </Toolbar>                
        </AppBar>           
      </ThemeProvider>
    </Box>
    </>
  );
}



